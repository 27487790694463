import request from "../utils/request";

class AdminService {
  static getVacantes(query = {}) {
    return request({
      url: "/admin/company/vacantes",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static getVacantesCount(query = {}) {
    return request({
      url: "/get/count/vacantes",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static aceptVacante(data) {
    return request({
      url: "/admin/vacante",
      method: "post",
      timeout: 6000,
      data,
    });
  }
  static deleteVacante(query = {}) {
    return request({
      url: "/admin/vacante",
      method: "delete",
      timeout: 6000,
      params: query,
    });
  }
  static getUserTotalCount(query = {}) {
    return request({
      url: "/usuariosRegistrados/count",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static getStudentCount(query = {}) {
    return request({
      url: "/get/count/estudiantes",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static getStudent(query = {}) {
    return request({
      url: "/estudiantes",
      method: "get",
      timeout: 100000,
      params: query,
    });
  }
  static getCompany(query = {}) {
    return request({
      url: "/empresas",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static getCompanyCount(query = {}) {
    return request({
      url: "/get/count/empresas",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static aceptUser(data) {
    return request({
      url: "/admin/user",
      method: "post",
      timeout: 6000,
      data,
    });
  }
  static deleteUser(query = {}) {
    return request({
      url: "/admin/user",
      method: "delete",
      timeout: 6000,
      params: query,
    });
  }
  static aceptCompany(data) {
    return request({
      url: "/admin/empresas",
      method: "post",
      timeout: 6000,
      data,
    });
  }
  static updateMode(data) {
    return request({
      url: "/admin/empresas/mode/update",
      method: "PUT",
      timeout: 10000,
      data,
    });
  }
  static deleteCompany(query = {}) {
    return request({
      url: "/admin/empresas",
      method: "delete",
      timeout: 6000,
      params: query,
    });
  }
  static getCountPreUniversitarios(query = {}) {
    return request({
      url: "/get/count/preUniversitario",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static getCountUniversitarios(query = {}) {
    return request({
      url: "/get/count/universitario",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static getCountExUniversitarios(query = {}) {
    return request({
      url: "/get/count/exUniversitario",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static getCountUsuariosTotales(query = {}) {
    return request({
      url: "/get/count/registrosTotales",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static getCountUsuariosNoRegistrados(query = {}) {
    return request({
      url: "/get/count/registrosFalse",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static getUsuariosNoRegistrados(query = {}) {
    return request({
      url: "/admin/registrosIncompletos",
      method: "get",
      params: query,
      timeout: 100000,
    });
  }
  static getUniversidadesAdmin(query = {}) {
    return request({
      url: "/universidades",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
  static updateModeUniversity(data) {
    return request({
      url: "/admin/universidades/mode/update",
      method: "PUT",
      timeout: 10000,
      data,
    });
  }
  static updateUniversityLinks(data) {
    return request({
      url: "/admin/universidades/updatelinks",
      method: "PUT",
      timeout: 10000,
      data,
    });
  }
  static aceptUniversity(data) {
    return request({
      url: "/admin/universidades",
      method: "post",
      timeout: 6000,
      data,
    });
  }
}
export default AdminService;
